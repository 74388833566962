<template>
    <div class="admin">
        <Menu></Menu>

        <router-view></router-view>
    </div>
</template>

<script>
import Menu from "@/components/Admin/Menu/Menu.vue";

export default {
    components: {
        Menu
    },
    data() {
        return {};
    }
};
</script>

<style lang="sass">
@import "./Admin.scss"
</style>
